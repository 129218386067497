// Generated by Framer (1119174)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/GGWy3uJpQ";

const cycleOrder = ["FJdYuAX0b", "CZc1PkQ37"];

const serializationHash = "framer-XEWFz"

const variantClassNames = {CZc1PkQ37: "framer-v-7c84ox", FJdYuAX0b: "framer-v-1hcf005"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Default: "FJdYuAX0b", Selected: "CZc1PkQ37"}

const getProps = ({height, id, link, tap, title, width, ...props}) => { return {...props, kOTLH1z33: tap ?? props.kOTLH1z33, mFurRcclq: link ?? props.mFurRcclq, slatEbBEx: title ?? props.slatEbBEx ?? "All", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FJdYuAX0b"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string;link?: string;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, slatEbBEx, mFurRcclq, kOTLH1z33, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "FJdYuAX0b", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap18sfp5p = activeVariantCallback(async (...args) => {
if (kOTLH1z33) {
const res = await kOTLH1z33(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "CZc1PkQ37") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-XEWFz", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><Link href={mFurRcclq} openInNewTab={false}><motion.a {...restProps} className={`${cx("framer-1hcf005", className)} framer-x5ag6d`} data-framer-name={"Default"} data-highlight layoutDependency={layoutDependency} layoutId={"FJdYuAX0b"} onTap={onTap18sfp5p} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({CZc1PkQ37: {"data-framer-name": "Selected"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<motion.div className={"framer-tedfxd"} data-framer-name={"Ellipse 588"} layoutDependency={layoutDependency} layoutId={"ooRrkjNXK"} style={{backgroundColor: "rgb(136, 219, 223)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/>)}<RichText __fromCanvasComponent children={<React.Fragment><motion.h2 className={"framer-styles-preset-1b7gexx"} data-styles-preset={"GGWy3uJpQ"}>All</motion.h2></React.Fragment>} className={"framer-1cwinpx"} data-framer-name={"All"} layoutDependency={layoutDependency} layoutId={"AIwA2ah5z"} style={{"--framer-paragraph-spacing": "0px"}} text={slatEbBEx} verticalAlignment={"center"} withExternalLayout/></motion.a></Link></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-XEWFz [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XEWFz .framer-x5ag6d { display: block; }", ".framer-XEWFz .framer-1hcf005 { align-content: start; align-items: start; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; text-decoration: none; width: min-content; }", ".framer-XEWFz .framer-tedfxd { bottom: 33px; flex: none; height: 28px; overflow: hidden; position: absolute; right: -14px; width: 28px; will-change: var(--framer-will-change-override, transform); z-index: 1; }", ".framer-XEWFz .framer-1cwinpx { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XEWFz .framer-1hcf005 { gap: 0px; } .framer-XEWFz .framer-1hcf005 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-XEWFz .framer-1hcf005 > :first-child { margin-left: 0px; } .framer-XEWFz .framer-1hcf005 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 104
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"CZc1PkQ37":{"layout":["auto","auto"]}}}
 * @framerVariables {"slatEbBEx":"title","mFurRcclq":"link","kOTLH1z33":"tap"}
 * @framerImmutableVariables true
 */
const FramerFGMO_m3oK: React.ComponentType<Props> = withCSS(Component, css, "framer-XEWFz") as typeof Component;
export default FramerFGMO_m3oK;

FramerFGMO_m3oK.displayName = "Work tab";

FramerFGMO_m3oK.defaultProps = {height: 104, width: 31};

addPropertyControls(FramerFGMO_m3oK, {variant: {options: ["FJdYuAX0b", "CZc1PkQ37"], optionTitles: ["Default", "Selected"], title: "Variant", type: ControlType.Enum}, slatEbBEx: {defaultValue: "All", displayTextArea: false, title: "Title", type: ControlType.String}, mFurRcclq: {title: "Link", type: ControlType.Link}, kOTLH1z33: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerFGMO_m3oK, [...sharedStyle.fonts])